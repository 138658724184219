<template>
  <div
    class="action-row-layer flex justify-start items-center w-full border-none py-0.5 pr-1"
    :class="bgClass"
  >
    <div
      v-if="isCollapsable"
      class="btn btn-no-style btn-xs w-8 text-gray-400"
      @click.prevent="$emit('toggle-block-collapse')"
    >
      <template v-if="isCollapsable">
        <fa-icon
          v-if="collapsed"
          icon="caret-right"
          class="fa-fw"
        />
        <fa-icon
          v-else
          icon="caret-down"
          class="fa-fw"
        />
      </template>
    </div>
    <span
      v-else
      class="ml-8"
    />

    <div class="flex justify-between w-full truncate">
      <div
        class="left-side flex justify-start items-center min-w-0 group py-2 shrink-0"
        @click.prevent="$emit('toggle-block-collapse')"
      >
        <div class="flex items-center">
          <div
            v-if="hasIcon"
            class="cursor-grab active:cursor-grabbing"
          >
            <fa-icon
              :icon="icon"
              class="fa-fw mr-2 text-2xs"
              :class="iconClass"
            />
          </div>
        </div>
        <div class="w-full flex truncate items-center">
          <div
            class="truncate select-none text-xs"
            :class="{ 'hover:underline cursor-pointer' : isCollapsable }"
          >
            {{ label }}
          </div>
        </div>
        <fa-icon
          v-if="helpText"
          v-tooltip="helpText"
          icon="circle-info"
          class="fa-fw ml-2 text-2xs text-gray-500"
        />
      </div>
      <div class="right-side flex justify-between items-center text-xs ml-2 truncate pr-1">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CollapseHeader',

  props: {
    label: {
      type: String,
      default: ''
    },

    collapsed: {
      type: Boolean,
      default: false
    },

    icon: {
      default: ''
    },

    helpText: {
      type: String,
      default: ''
    },

    iconClass: {
      type: String,
      default: ''
    },

    bgClass: {
      type: String,
      default: ''
    },

    collapsable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isCollapsable () {
      return this.collapsable === true
    },

    hasIcon () {
      return this.icon !== ''
    }
  }

}
</script>
